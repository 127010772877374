import React from "react"
import { Image } from "components/image/Image"

const ReviewImage = ({ image }) => {
  return (
    <Image
      alt={image?.alternativeText ?? ""}
      className="review-image"
      image={image}
    />
  )
}
export default ReviewImage
