import React from "react"
import { graphql, useStaticQuery, PageProps } from "gatsby"
import Layout from "components/layout"
import SEO from "components/seo/seo"
import GoogleRating from "components/google-rating-banner/GoogleRating"
import ReviewList from "components/reviews/ReviewList"
import SimpleHero from "components/simple-hero/SimpleHero"
import SingleReview from "components/reviews/SingleReview"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { EMPTY_PAGE } from "assets/js/EmptyPage"

type PageContext = PageProps["pageContext"] & {
  slug: string
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  contentUrlPathCodex: Record<string, string>
  linkedPagesUrlPathCodex: Record<string, string>
}

interface ReviewsPageProps extends PageProps {
  pageContext: PageContext
  data: any
}

const ReviewsPage: React.FC<ReviewsPageProps> = ({ location, pageContext }) => {
  const { pathname, hash } = location
  const { contentUrlPathCodex, linkedPagesUrlPathCodex, slug } = pageContext
  const data = useStaticQuery(getReviewsQuery)
  const [reviewsPage] = data.allStrapiPage.nodes
  const { custom_tracking_number, hero, meta_data } = reviewsPage ?? EMPTY_PAGE

  const { googleReviewsUrl } = useIntegrationsSettings()

  return (
    <Layout
      contentUrlPathCodex={contentUrlPathCodex}
      customTrackingNumber={custom_tracking_number}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
      location={slug}
      pathName={pathname}
    >
      <SEO
        canonical={meta_data?.canonical_link ?? ""}
        title={meta_data?.meta_title ?? ""}
        description={meta_data?.meta_description ?? ""}
      />

      <SimpleHero banner={hero} />

      {googleReviewsUrl && <GoogleRating url={googleReviewsUrl} />}

      <div className="container">
        <SingleReview />
      </div>

      <ReviewList />
    </Layout>
  )
}

const getReviewsQuery = graphql`
  query pestroutesReviews {
    allStrapiPage(filter: { slug: { eq: "reviews" } }) {
      nodes {
        custom_tracking_number
        meta_data {
          canonical_link
          meta_description
          meta_title
        }
        hero {
          ...StrapiHeroBannerFragment
        }
      }
    }
  }
`

export default ReviewsPage
