import React, { useState, useEffect } from "react"
import { useBackgroundImage } from "hooks/useBackgroundImage"
import * as styles from "./simple-hero.module.scss"

const SimpleHero = ({ banner, tag = "", category = "" }) => {
  const [offset, setOffset] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.pageYOffset)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const { background_image, heading } = banner

  const backgroundImageUrl = background_image?.localFile.publicURL

  const backgroundImageCSSVariable = useBackgroundImage({
    backgroundImageUrl,
    variable: "hero",
  })

  const bgOverlay = "PrimaryColor"

  if (backgroundImageUrl) {
    return (
      <div className={styles.simpleHero} id="simple-hero-override">
        <div
          className={styles.simpleHeroBgImage}
          style={{
            ...backgroundImageCSSVariable,
            transform: `translateY(-${offset * 0.65}px)`,
          }}
        />

        <section className={`container ${styles.bannerContent}`}>
          {category ? (
            <h1>{`Articles tagged with ${category}`}</h1>
          ) : (
            <h1>{heading}</h1>
          )}
        </section>
      </div>
    )
  } else {
    return (
      <section
        className={`${styles.simpleHeroTextOnly} ${bgOverlay}Bg`}
        id="simple-hero-override"
      >
        <div className={`container ${styles.bannerContentTextOnly}`}>
          {category ? <h1>{category}</h1> : <h1>{heading}</h1>}
        </div>
      </section>
    )
  }
}

export default SimpleHero
