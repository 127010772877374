import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import * as styles from "./googleRating.module.scss"

const GoogleRating = ({ url }) => {
  const data = useStaticQuery(getData)
  const { averageRating, isDevelop, noCompass } = data.average
  const { totalReviews } = data.count
  const { companyName } = useGeneralSettings()

  if ((!isDevelop && noCompass) || !averageRating) return null
  const averageRatingFormatted = Number(averageRating).toFixed(1)

  return (
    <div className={styles.googleRatingWrapper}>
      {/* google logo here */}
      <span id="google-reviews">
        <span className={styles.reviewWrapper}>
          <div className="no-margin">
            <strong>
              <span>{companyName}</span>
            </strong>
            {` received an average rating of `}
            <strong>
              <span>{averageRatingFormatted}</span>{" "}
            </strong>
            out of{" "}
            <strong>
              <span>5</span> stars
            </strong>{" "}
            from{" "}
            <strong>
              <span>{totalReviews}</span> reviews.
            </strong>
          </div>
          <div className="no-margin flex center">
            <style
              dangerouslySetInnerHTML={{
                __html: `:root{ --rating: ${averageRating}}`,
              }}
            ></style>
            <div
              className={styles.stars}
              aria-label={`Rating of this product is ${averageRatingFormatted} out of 5.`}
            >
              {averageRatingFormatted}
            </div>
            <a href={url.value} target="_blank" rel=" noopener noreferrer">
              <strong>Read Google Reviews</strong>
            </a>
          </div>
        </span>
      </span>
    </div>
  )
}

const getData = graphql`
  query {
    average {
      averageRating
      isDevelopment
      hasNoCompass
    }
    count {
      totalReviews
    }
  }
`


export default GoogleRating
