import React, { useState, useEffect, useContext, useMemo } from "react"
import { Link } from "gatsby"
import { shuffle } from "assets/js/helper"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import ReviewImage from "./ReviewImage"
import { AppContext } from "components/context/context"

const SingleReview = () => {
  const [singleReview, setSingleReview] = useState(null)
  const [stars, setStars] = useState("★★★★★")

  const {
    googleReviewsUrl,
    fieldroutesReviewImages,
    fieldroutesReviewBackgroundImage,
    customHeadingFieldroutesReviews,
  } = useIntegrationsSettings()

  const {
    isPRClientAndJQueryScriptsLoaded,
    loadPestRoutesClient,
    handleLoadingPRClientAndJQueryScripts,
  } = useContext(AppContext)

  /* eslint-disable */
  const getPestroutesClientReviews = async () => {
    if (typeof PestroutesClient !== "object") {
      return setTimeout(getPestroutesClientReviews, 100)
    }

    let result

    try {
      result = await PestroutesClient.getReviews({
        favorable: 1,
        minRating: 4,
        limit: 15,
        randomize: true,
      })
    } catch {
      return setTimeout(getPestroutesClientReviews, 100)
    }

    //remove reviews containing profanities
    const filteredReviews = result.reviews.filter(
      review => !review.profane && review.feedback.length > 10
    )

    const hasReviews = !filteredReviews || filteredReviews.length === 0

    if (hasReviews) {
      console.error(
        "There are no reviews from FieldRoutes for this website, please disable the option in the settings"
      )
      return
    }

    //referenced randomized review to be used in the for loop
    const randomSingleReview = shuffle(filteredReviews)

    let reviewStars = ""
    for (let i = 0; i < randomSingleReview.rating; i++) {
      reviewStars += "★"
    }

    setSingleReview(randomSingleReview)
    setStars(reviewStars)
    return
  }

  /* eslint-disable */
  useEffect(() => {
    let mounted = true

    // avoids loading jquery before the LCP is done
    if (typeof PestroutesClient !== "object") {
      setTimeout(() => {
        if (mounted) {
          loadPestRoutesClient()
          handleLoadingPRClientAndJQueryScripts(true)
        }
      }, 4000)
    } else {
      handleLoadingPRClientAndJQueryScripts(true)
    }

    return () => {
      mounted = false
    }
  }, [])

  /* eslint-disable */
  useEffect(() => {
    if (!isPRClientAndJQueryScriptsLoaded) return
    getPestroutesClientReviews()
  }, [isPRClientAndJQueryScriptsLoaded])
  const randomReviewImage = useMemo(
    () => shuffle(fieldroutesReviewImages)?.single_image,
    [fieldroutesReviewImages]
  )
  return (
    <>
      {singleReview && (
        <div className="singleReview_container container">
          <div className="singleReview_info">
            <h2>Customer Reviews</h2>
            <div className="review_quote">{singleReview.feedback}</div>
            <div className="review_details">
              <div>
                <strong>{singleReview.reviewer}</strong>
                <span className="review_details_separator">|</span>
              </div>
              <div>
                {singleReview.city}, {singleReview.state}
                <span className="review_details_separator">|</span>
              </div>
              <div>
                <Link to="/reviews">Read Reviews</Link>
              </div>
            </div>
            <div>
              <div className="review_stars">{stars}</div>
            </div>
          </div>

          <ReviewImage image={randomReviewImage} />
        </div>
      )}
    </>
  )
}

export default SingleReview
